import { graphql } from "gatsby"

import PeopleGrid from "../components/people/people-grid"
import TableOfContents from "../components/toc/toc"

import ContentLayout from "../layouts/content-layout"
import Seo from "../layouts/seo"
import ThreeQuarterLayout from "../layouts/three-quarter-layout"
import getGroupMap from "../lib/group-map"
import getImageMap from "../lib/image-map"
import IDataPageProps from "../interfaces/data-page-props"
import * as React from "react"

export default function Page({ data, location }: IDataPageProps) {
  const groupMap = getGroupMap(data.people.nodes)
  const imageMap = getImageMap(data)

  return (
    <ContentLayout title="People" showTitle={false} location={location}>
      <></>
      <ThreeQuarterLayout
        isRight={false}
        sideClassName="hidden xl:block"
        className="main-content"
      >
        <div className="mb-64 flex w-full flex-col gap-y-8">
          {/* <section>
          <h1 className="mb-4 text-2xl">Director</h1>

          <PeopleGrid
            people={
              // @ts-ignore
              groupMap["Director"]
            }
            imageMap={imageMap}
            showUrl={true}
          />
        </section> */}

          <section>
            <h1 id="faculty" className="mb-8 text-3xl font-semibold">
              Faculty
            </h1>
            <PeopleGrid
              people={
                // @ts-ignore
                groupMap["Director"].concat(groupMap["Faculty"])
              }
              imageMap={imageMap}
              showUrl={true}
            />
          </section>

          <section className="mt-8">
            <h1 id="research-staff" className="mb-8 text-3xl font-semibold">
              Research Staff
            </h1>
            <PeopleGrid
              people={
                // @ts-ignore
                groupMap["Research Staff"]
              }
              imageMap={imageMap}
            />
          </section>

          {/* <section className="mt-8">
            <h1 id="technicians" className="mb-4 text-3xl">
              Technicians
            </h1>
            <PeopleGrid
              people={
                // @ts-ignore
                groupMap["Technicians"]
              }
              imageMap={imageMap}
            />
          </section> */}

          <section className="mt-8">
            <h1
              id="administrative-staff"
              className="mb-8 text-3xl font-semibold"
            >
              Administrative Staff
            </h1>
            <PeopleGrid
              people={
                // @ts-ignore
                groupMap["Administrative Staff"]
              }
              imageMap={imageMap}
            />
          </section>
        </div>

        <TableOfContents className="text-sm" />
      </ThreeQuarterLayout>
    </ContentLayout>
  )
}

export const Head = () => <Seo title="People" />

export const pageQuery = graphql`
  query {
    images: allFile(
      filter: {
        absolutePath: { regex: "/images/people/600x600/" }
        ext: { regex: "/webp/" }
      }
    ) {
      nodes {
        name
        ext
        relativePath
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, aspectRatio: 1)
        }
      }
    }

    genericPersonImage: file(
      absolutePath: { regex: "/images/people/600x600/generic.webp/" }
    ) {
      name
      ext
      relativePath
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, aspectRatio: 1)
      }
    }

    people: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/people/" } }
    ) {
      nodes {
        excerpt(format: HTML)
        fields {
          slug
        }
        frontmatter {
          personId
          name
          titles
          groups {
            lab
            admin
          }
          labs

          headshot
        }
      }
    }
  }
`
