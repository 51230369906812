// "use client"

import * as React from "react"
import { useState } from "react"
import cn from "../../lib/class-names"
//import ContactInfo from "./contactinfo"
import getContextName from "../../lib/context-name"
import getTitleMap from "../../lib/title-map"
import IFieldMap from "../../interfaces/field-map"
import IPerson from "../../interfaces/person"
import HCenterRow from "../h-center-row"
import BWImage from "../bw-image"
import BaseLink from "../link/base-link"
import BlueLink from "../link/blue-link"

interface IPersonCardProps {
  person: IPerson
  context?: string
  isFaculty?: boolean
  showUrl?: boolean
  photoMode?: string
  showLetters?: boolean
  showPhone?: boolean
  imageMap: IFieldMap
}

export default function PersonCard({
  person,
  showUrl,
  photoMode = "show,generic",
  showLetters,
  showPhone,
  imageMap,
}: IPersonCardProps) {
  const [hover, setHover] = useState(false)

  function onMouseEnter(e: any) {
    setHover(true)
  }

  function onMouseLeave(e: any) {
    setHover(false)
  }

  let fluid = null //imageMap["generic"]
  let b = "border-gray-100"

  if (photoMode.includes("show")) {
    if (person.frontmatter.personId in imageMap) {
      fluid = imageMap[person.frontmatter.personId] //`/assets/images/people/600x600/${person.frontmatter.personId}.webp`
      b = "border-transparent"
    }
  }

  const titleMap = getTitleMap(person.frontmatter.titles)

  const titles = getContextName(titleMap).split(";")

  let img

  const alt = `Headshot of ${person.frontmatter.name}`

  if (fluid) {
    img = (
      <BWImage
        image={fluid}
        extZoom={hover}
        alt={alt}
        imgClassName="rounded-full overflow-hidden"
        className={cn(`w-56 overflow-hidden rounded-full border lg:w-full`, b)}
      />
    )
  } else {
    img = (
      <img
        src="/assets/svg/generic-person.svg"
        className={cn(
          `w-56 overflow-hidden rounded-full border bg-white lg:w-full`,
          b
        )}
        width="100"
        height="100"
        alt={alt}
      />
    )
  }

  return (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {photoMode.includes("generic") && (
        <HCenterRow>
          {showUrl ? (
            <BaseLink
              href={`/people/${person.frontmatter.personId}`}
              ariaLabel={`View profile for ${person.frontmatter.name}`}
            >
              {img}
            </BaseLink>
          ) : (
            img
          )}
        </HCenterRow>
      )}

      {showUrl ? (
        <BlueLink
          href={`/people/${person.frontmatter.personId}`}
          ariaLabel={`View profile for ${person.frontmatter.name}`}
          className="mt-4 block font-medium"
        >
          {person.frontmatter.name}
        </BlueLink>
      ) : (
        <p className="mt-4 font-medium">{person.frontmatter.name}</p>
      )}

      <p className="text-sm">{titles[0]}</p>
    </div>
  )
}
