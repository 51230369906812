// "use client"

import * as React from "react"
import { useState } from "react"
import IClassProps from "../interfaces/class-props"
import BaseImage from "./base-image"

interface ImageProps extends IClassProps {
  image: any
  extZoom?: any
  alt: string
  imgClassName?: string
  onMouseEnter?: any
  onMouseLeave?: any
  colorMode?: boolean
  loading?: "lazy" | "eager" | undefined
}

export function BWImage({
  image,
  extZoom,
  alt,
  imgClassName,
  className,
  onMouseEnter,
  onMouseLeave,
  colorMode = true,
  loading = "lazy",
}: ImageProps) {
  const [hover, setHover] = useState(false)

  //const photoEl = useRef(null)

  // useEffect(() => {
  //   gsap
  //     .timeline()
  //     .to(
  //       photoEl.current,
  //       0,
  //       { opacity: extZoom ? 1 : 0.9, ease: "power3.inOut" },
  //       0
  //     )
  //     .to(
  //       photoEl.current,
  //       0.5,
  //       {
  //         filter: extZoom ? "grayscale(0)" : "grayscale(1)",
  //         ease: "power3.inOut",
  //       },
  //       0
  //     )
  // }, [extZoom])

  function _onMouseEnter(e: any) {
    setHover(true)

    if (onMouseEnter !== null) {
      onMouseEnter(e)
    }
  }

  function _onMouseLeave(e: any) {
    setHover(false)

    if (onMouseLeave !== null) {
      onMouseLeave(e)
    }
  }

  return (
    <BaseImage
      image={image}
      style={{
        filter: `grayscale(${colorMode && (extZoom || hover) ? "0" : "1"})`,
        transition: "all 0.3s ease-in-out",
      }}
      className={className}
      imgClassName={imgClassName}
      alt={alt}
      loading={loading}
      onMouseEnter={_onMouseEnter}
      onMouseLeave={_onMouseLeave}
    />
  )
}

export default BWImage
