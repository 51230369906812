import * as React from "react"
import cn from "../../lib/class-names"
//import ContactInfo from "./contactinfo"
import IFieldMap from "../../interfaces/field-map"
import IPerson from "../../interfaces/person"
import PersonCard from "./person-card"

interface PeopleGridProps {
  people: IPerson[]
  photoMode?: string
  showUrl?: boolean
  showPhone?: boolean
  headingColor?: string
  context?: string
  gridBg?: string
  outline?: boolean
  showLetters?: boolean
  className?: string
  imageMap: IFieldMap
}

function PeopleGrid({
  people,
  photoMode,
  showUrl = true,
  imageMap,
  showLetters,
  showPhone,
  context,
  className,
}: PeopleGridProps) {
  const ret: any[] = []

  people.map((person: any, index: number) => {
    ret.push(
      <li key={`person-${index}`}>
        <PersonCard
          person={person}
          context={context}
          showUrl={showUrl}
          imageMap={imageMap}
          photoMode={photoMode}
          showLetters={showLetters}
          showPhone={showPhone}
        />
      </li>
    )
  })

  return (
    // <Card showCard={false}>

    <ul
      className={cn(
        `grid grid-cols-1 gap-12 sm:grid-cols-2 md:grid-cols-3 2xl:grid-cols-4`,
        className
      )}
    >
      {ret}
    </ul>
    // </Card>
  )
}

export default PeopleGrid
